<template>
  <b-overlay
    :show="isLoading"
    :opacity="0.6"
    spinner-variant="primary"
    spinner-type="grow"
    rounded="sm"
  >
    <b-card-code>

      <b-row>
        <b-col
          cols="12"
          class="mb-2"
        >
          <validation-observer ref="simpleRules">
            <b-form>
              <b-row class="mb-3">
                <b-col md="9">
                  <b-form-group>
                    <validation-provider
                      #default="{ errors }"
                      :name="$t('group_q')"
                    >
                      <label
                        :class="errors.length > 0 ? 'text-danger': null"
                      >{{ $t('group_q') }} <span class="text-danger">(*)</span></label>
                      <quill-editor
                        v-model="frm.questionParent.name"
                        :options="snowOption"
                        :placeholder="$t('group_q')"
                        max-rows="6"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
              <validation-observer ref="addQuestion">
                <validation-provider
                  #default="{ errors }"
                  :name="'question'"
                  rules="required"
                >
                  <b-row
                    v-for="(child,index) in frm.questionChild"
                    :key="index"
                    class="align-items-center"
                  >
                    <b-col
                      cols="md-9"
                      class="box_content_quiz"
                    >
                      <b-row>
                        <b-col cols="6">
                          <validation-provider
                            #default="{ errors }"
                            name="Tên đăng nhập"
                            rules="required"
                          >
                            <label :class="errors.length > 0 ? 'text-danger': null">
                              {{ $t('name_q') }} <span class="text-danger">(*)</span></label>
                            <b-form-input
                              id="name"
                              v-model="frm.questionChild[index].name"
                              :state="errors.length > 0 ? false:null"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-col>
                        <b-col md="6">
                          <validation-provider
                            #default="{ errors }"
                            name="loại câu hỏi"
                            vid="type"
                            rules="required"
                          >
                            <label
                              :class="errors.length > 0 ? 'text-danger': null"
                            >{{ $t('type') }} <span class="text-danger">(*)</span></label>
                            <b-form-group>
                              <v-select
                                id="type"
                                v-model="frm.questionChild[index].type"
                                class=""
                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                label="label"
                                :options="questionTypeData"
                                :reduce="label => label.value"
                                :state="errors.length > 0 ? false:null"
                                :clearable="false"
                              />
                            </b-form-group>
                          </validation-provider>
                        </b-col>
                      </b-row>
                      <b-list-group class="group_quiz_create">
                        <draggable
                          v-model="frm.questionChild[index].answers"
                          tag=""
                          @end="value => onEnd(value)"
                        >
                          <b-list-group-item
                            v-for="(value, index2) in frm.questionChild[index].answers"
                            :key="index2"
                            class="item_quiz_create"
                          >
                            <b-row>
                              <b-col cols="1">
                                <b-badge
                                  variant="light-danger"
                                  class="badge-light-custom"
                                >
                                  {{ answerIndex[index2] }}
                                </b-badge>
                              </b-col>
                              <b-col cols="9">
                                <div>
                                  <validation-provider
                                    #default="{ errors }"
                                    name="đáp án"
                                    vid="answer_name"
                                    rules="required"
                                  >
                                    <template v-if="frm.questionChild[index].type === 1">
                                      <b-form>
                                        <quill-editor
                                          v-model="value.name"
                                          :options="snowOptionAnswer"
                                          :placeholder="'Nhập đáp án'"
                                          max-rows="6"
                                        />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                      </b-form>
                                    </template>

                                    <template v-else-if="frm.questionChild[index].type === 3">
                                      <b-form-textarea
                                        v-model="value.name"
                                        placeholder="Nhập đáp án"
                                        rows="3"
                                        max-rows="6"
                                        :state="errors.length > 0 ? false:null"
                                      />
                                    </template>
                                  </validation-provider>
                                </div>
                              </b-col>
                              <b-col
                                cols="1"
                                class="border-checked-none"
                              >
                                <b-input-group-append
                                  v-if="frm.questionChild[index].type === 1"
                                  style="margin-top: 70px"
                                  is-text
                                  class="custom_radio_checker"
                                >
                                  <b-form-radio
                                    v-model="frm.questionChild[index].is_correct"
                                    :value="index2"
                                    :name="`radio-input-${index}`"
                                  />
                                </b-input-group-append>
                              </b-col>
                              <b-col cols="1">
                                <feather-icon
                                  style="margin-top: 70px"
                                  class="btn_close_item"
                                  icon="XIcon"
                                  @click="removeAnswer(index,index2)"
                                />
                              </b-col>
                            </b-row>
                          </b-list-group-item>
                        </draggable>
                      </b-list-group>
                      <b-row>
                        <b-col cols="6">
                          <validation-provider
                            #default="{ errors }"
                            name="ảnh"
                            vid="image"
                          >
                            <label
                              :class="errors.length > 0 ? 'text-danger': null"
                            >{{ $t('img') }}</label>
                            <b-form-file
                              v-model="frm.questionChild[index].image"
                              class="input_file updload_file_img1"
                              placeholder=""
                              accept="image/*"
                              :state="errors.length > 0 ? false:null"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                          <b-card-text
                            v-if="imgUrl"
                            class="mt-1"
                            style="position: relative"
                          >
                            <b-img
                              center
                              class="mb-1 mb-sm-0"
                              height="150"
                              :src="endPoint + '/storage' + imgUrl"
                              alt="Right image"
                            />
                          </b-card-text>
                        </b-col>
                        <b-col cols="6">
                          <b-form-group>
                            <validation-provider
                              #default="{ errors }"
                              name="audio"
                              vid="audio"
                            >
                              <label
                                :class="errors.length > 0 ? 'text-danger': null"
                              >{{ $t('audio') }}</label>
                              <b-form-file
                                v-model="frm.questionChild[index].audio"
                                class="input_file updload_file_audio"
                                placeholder=""
                                drop-placeholder="Drop file here..."
                                accept=".mp3"
                                :state="errors.length > 0 ? false:null"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                            <div
                              v-if="audioUrl"
                              style="position: relative;"
                            >
                              <b-embed
                                type="audio"
                                aspect="4by3"
                                controls
                                poster="poster.png"
                              >
                                <source
                                  :src="endPoint + '/storage' + audioUrl"
                                  type="video/webm"
                                >
                              </b-embed>
                              <!--                              <span-->
                              <!--                                class="btn_remove_imgUpload"-->
                              <!--                                @click="deleteMedia(item.id,0)"-->
                              <!--                              >-->
                              <!--                                <svg-->
                              <!--                                  xmlns="http://www.w3.org/2000/svg"-->
                              <!--                                  fill="currentColor"-->
                              <!--                                  viewBox="0 0 512 512"-->
                              <!--                                ><path-->
                              <!--                                  d="M175 175C184.4 165.7 199.6 165.7 208.1 175L255.1 222.1L303 175C312.4 165.7 327.6 165.7 336.1 175C346.3 184.4 346.3 199.6 336.1 208.1L289.9 255.1L336.1 303C346.3 312.4 346.3 327.6 336.1 336.1C327.6 346.3 312.4 346.3 303 336.1L255.1 289.9L208.1 336.1C199.6 346.3 184.4 346.3 175 336.1C165.7 327.6 165.7 312.4 175 303L222.1 255.1L175 208.1C165.7 199.6 165.7 184.4 175 175V175zM512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256zM256 48C141.1 48 48 141.1 48 256C48 370.9 141.1 464 256 464C370.9 464 464 370.9 464 256C464 141.1 370.9 48 256 48z"-->
                              <!--                                /></svg>-->
                              <!--                              </span>-->
                            </div>
                          </b-form-group>
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col cols="2">
                          <div class="form-label-group d-flex">
                            <b-form-checkbox
                              id="status1"
                              v-model="frm.questionChild[index].status"
                              class="custom-control-danger"
                              name="is_active"
                              switch
                            />
                            <div>{{ $t('status') }}</div>
                          </div>
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col cols="2">
                          <b-button
                            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                            v-b-tooltip.click.v-primary
                            variant="outline-secondary"
                            :title="$t('addNewAnswer')"
                            class="btn-icon float-left mt-1 button_create_ansewer"
                            style=""
                            @click="addAnswer(index)"
                          >
                            <feather-icon icon="PlusIcon" />
                          </b-button>
                        </b-col>
                      </b-row>
                    </b-col>
                    <!--                    <small class="text-danger">{{  errors[0] }}</small>-->
                    <b-col
                      v-if="frm.questionChild.length"
                      cols="md-3"
                      class="item_quiz_create"
                    >
                      <feather-icon
                        class="btn_close_item"
                        icon="XIcon"
                        @click="removeQuestion(index)"
                      />
                    </b-col>
                  </b-row>

                </validation-provider>
              </validation-observer>
              <b-row class="mb-2">
                <b-col cols="2">
                  <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    v-b-tooltip.click.v-primary
                    variant="outline-secondary"
                    :title="$t('add_question')"
                    class="btn-icon float-left mt-1 button_create_ansewer"
                    style=""
                    @click="addQuestion"
                  >
                    <feather-icon icon="PlusIcon" />
                  </b-button>
                </b-col>
              </b-row>
              <b-row>
                <b-col md="6">
                  <b-form-group>
                    <validation-provider
                      #default="{ errors }"
                      :name="'tag'"
                      rules="required"
                    >
                      <label
                        :class="errors.length > 0 ? 'text-danger': null"
                      >Tag <span class="text-danger">(*)</span></label>
                      <v-select
                        id="tag"
                        v-model="frm.questionParent.tag"
                        multiple
                        class=""
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        label="name"
                        :options="tagOptions"
                        :reduce="label => label.id"
                        :state="errors.length > 0 ? false:null"
                        :clearable="false"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <div class="form-label-group d-flex mt-2">
                    <b-form-checkbox
                      id="status1"
                      v-model="frm.questionParent.status"
                      class="custom-control-danger"
                      name="is_active"
                      switch
                    />
                    <div>{{ $t('status') }}</div>
                  </div>
                </b-col>
              </b-row>
              <b-row class="mt-2">
                <b-col cols="6">
                  <validation-provider
                    #default="{ errors }"
                    name="ảnh"
                    vid="image"
                  >
                    <label
                      :class="errors.length > 0 ? 'text-danger': null"
                    >{{ $t('img') }}</label>
                    <b-form-file
                      v-model="frm.questionParent.image"
                      class="input_file updload_file_img"
                      placeholder=""
                      accept="image/*"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>

                  <div
                    v-if="frm.questionParent.image"
                    class="my-1"
                  >
                    File đã chọn: <strong>{{ frm.image.name }}</strong>
                  </div>
                  <b-card-text
                    v-if="imgUrl"
                    class="mt-1"
                    style="position: relative"
                  >
                    <!--                              <span-->
                    <!--                                class="btn_remove_imgUpload"-->
                    <!--                                @click="deleteMedia(item.id,1)"-->
                    <!--                              >-->
                    <!--                                <svg-->
                    <!--                                  xmlns="http://www.w3.org/2000/svg"-->
                    <!--                                  fill="currentColor"-->
                    <!--                                  viewBox="0 0 512 512"-->
                    <!--                                ><path-->
                    <!--                                  d="M175 175C184.4 165.7 199.6 165.7 208.1 175L255.1 222.1L303 175C312.4 165.7 327.6 165.7 336.1 175C346.3 184.4 346.3 199.6 336.1 208.1L289.9 255.1L336.1 303C346.3 312.4 346.3 327.6 336.1 336.1C327.6 346.3 312.4 346.3 303 336.1L255.1 289.9L208.1 336.1C199.6 346.3 184.4 346.3 175 336.1C165.7 327.6 165.7 312.4 175 303L222.1 255.1L175 208.1C165.7 199.6 165.7 184.4 175 175V175zM512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256zM256 48C141.1 48 48 141.1 48 256C48 370.9 141.1 464 256 464C370.9 464 464 370.9 464 256C464 141.1 370.9 48 256 48z"-->
                    <!--                                /></svg>-->
                    <!--                              </span>-->
                    <b-img
                      center
                      class="mb-1 mb-sm-0"
                      height="150"
                      :src="endPoint + '/storage' + imgUrl"
                      alt="Right image"
                    />
                  </b-card-text>
                </b-col>
                <b-col cols="6">
                  <b-form-group>
                    <validation-provider
                      #default="{ errors }"
                      name="audio"
                      vid="audio"
                    >
                      <label
                        :class="errors.length > 0 ? 'text-danger': null"
                      >{{ $t('audio') }}</label>
                      <b-form-file
                        v-model="frm.questionParent.audio"
                        class="input_file updload_file_audio"
                        placeholder=""
                        drop-placeholder="Drop file here..."
                        accept=".mp3"
                        :state="errors.length > 0 ? false:null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                    <div
                      v-if="audioUrl"
                      style="position: relative;"
                    >
                      <b-embed
                        type="audio"
                        aspect="4by3"
                        controls
                        poster="poster.png"
                      >
                        <source
                          :src="endPoint + '/storage' + audioUrl"
                          type="video/webm"
                        >
                      </b-embed>
                    </div>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-button
                variant="danger"
                class="btn btn-sm"
                style="float: right; margin: 20px 0; color: #fff"
                @click="handleSubmit"
              >
                {{ $t('save_info') }}
              </b-button>
            </b-form>
          </validation-observer>

        </b-col>
      </b-row>
    </b-card-code>

  </b-overlay>
</template>

<script>
import {
  VBTooltip,
  BFormRadioGroup,
  BTabs,
  BTab,
  BCardText,
  BFormInput,
  BFormGroup,
  BForm,
  BOverlay,
  BPagination,
  BFormCheckbox,
  BDropdown,
  BDropdownItem,
  BListGroup,
  BFormFile,
  BFormRadio,
  BInputGroupAppend,
  BBadge,
  BListGroupItem,
  BFormTextarea, BRow, BCol, BButton, BModal, VBModal,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import vSelect from 'vue-select'
import { quillEditor } from 'vue-quill-editor'
import Ripple from 'vue-ripple-directive'
import config from '@/config'
import BCardCode from '@core/components/b-card-code/BCardCode'
import draggable from 'vuedraggable'

export default {
  components: {
    BCardText,
    BTabs,
    BTab,
    BButton,
    ValidationProvider,
    ValidationObserver,
    vSelect,
    // eslint-disable-next-line vue/no-unused-components
    draggable,
    BFormFile,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BFormRadio,
    BInputGroupAppend,
    BDropdownItem,
    BListGroup,
    BModal,
    BBadge,
    BFormTextarea,
    ValidationProvider,
    ValidationObserver,
    BCardCode,
    vSelect,
    BRow,
    // eslint-disable-next-line vue/no-unused-components
    quillEditor,
    BCol,
    draggable,
    BFormFile,
    BForm,
    BOverlay,
    BPagination,
    BFormCheckbox,
    BFormRadio,
    BInputGroupAppend,
    BDropdown,
    BDropdownItem,
    BListGroup,
    BListGroupItem,
    BFormTextarea,
    VBTooltip,
  },
  directives: {
    'b-modal': VBModal,
    'b-tooltip': VBTooltip,
    Ripple,
  },

  data() {
    return {
      cbxAll: false,
      modalShow: false,
      currentPage: 1,
      showAddAnswer: false,
      frm: {
        questionParent: {
          name: '',
          status: 0,
          audio: '',
          type: '',
          image: '',
          tag: [],
        },
        questionChild: [],
        name: null,
        description: null,
        status: false,
        type: null,
        point: 0,
        image: null,
        audio: null,
      },
      pageLength: 10,
      isShow: true,
      dragging: false,
      titleAdd: this.$t('Add'),
      file: null,
      // eslint-disable-next-line global-require
      imgUrl: null,
      isLoading: false,
      total: 0,
      rows: [],
      ids: [],
      modalName: '',
      snowOption: {
        theme: 'snow',
        placeholder: this.$t('group_q'),
      },
      snowOptionAnswer: {
        theme: 'snow',
        placeholder: this.$t('enter_w'),
      },
      endPoint: process.env.VUE_APP_API_ENDPOINT,
      filter: {
        key: '',
        status: 'all',
        statusOptions: [
          { value: 'all', text: 'Tất cả' },
          { value: 1, text: 'Hoạt động' },
          { value: 0, text: 'Không hoạt động' },
        ],
      },
      sort: {
        by: 'order',
        direction: 'desc',
      },
      is_correct: null,
      answerIndex: config.course.answerIndex,
      questionTypeData: [
        {
          label: this.$t('multiple_c'),
          value: 1,
        },
        {
          label: this.$t('fill'),
          value: 3,
        },
      ],
      audioUrl: null,
      multipleChoiceTypeData: [
        {
          label: this.$t('1_a'),
          value: 1,
        },
        {
          label: this.$t('mutiple_a'),
          value: 2,
        },
      ],
      tagOptions: [
        {
          name: '100 Toeic',
          id: 1,
        },
        {
          name: '200 Toeic',
          id: 2,
        },
      ],

    }
  },
  methods: {
    addAnswer(index) {
      this.frm.questionChild[index].answers.push({
        name: '',
        is_correct: false,
      })

      this.add_answer = null
    },
    removeAnswer(index, index2) {
      // if (index < this.frm.is_correct) {
      //   this.frm.is_correct -= 1
      // } else if (index === this.frm.is_correct) {
      //   this.frm.is_correct = null
      // }

      this.frm.questionChild[index].answers.splice(index2, 1)
    },
    removeQuestion(index) {
      this.frm.questionChild.splice(index, 1)
    },
    addQuestion() {
      this.frm.questionChild.push({
        name: this.add_answer,
        answers: [],
        is_correct: 0,
        point: '',
        status: false,
        type: '',
        image: '',
        audio: '',
      })
    },
  },
}
</script>
